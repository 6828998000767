<template>
  <div class="container" v-if="searching">
    <div class="searchInput">
      <input type="text" placeholder="Nickname profilu" v-model="search" v-on:change="submit" />
      <div class="resultBox">
        <ul>
          <li v-for="(item, index) in listBox" :key="index" v-on:click="selectMethod(item.user.pk_id)"><img :src="'https://followershop.planer.workers.dev/' + item.user.profile_pic_url" />{{item.user.full_name}} (@{{ item.user.username }})</li>
        </ul>
      </div>
      <div class="icon"><i class="fas fa-search"></i></div>
    </div>
  </div>
  <div class="container-image" v-if="images">
    <div class="images">
      <img v-for="(item, index) in imageList" :key="index" width="320px" height="320px" :src="'https://followershop.planer.workers.dev/' + item.node.thumbnail_resources[2].src"/>
    </div>
  </div>
</template>

<script>
// import {  ref } from 'vue'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      search: "",
      listBox: [],
      searching: true,
      images: false,
      imageList: []
    }
  },
  methods: {
    async selectMethod(id) {
      console.log(id)
      this.searching = false
      this.images = true


      const axios = require('axios');

      const options = {
        method: 'GET',
        url: 'https://instagram-scraper-20231.p.rapidapi.com/userposts/' + id +'/50/%7Bend_cursor%7D',
        headers: {
          'X-RapidAPI-Key': 'dc2d3b6a28msh63bc89ad0929e18p1fe1f0jsn5847ba2f24d2',
          'X-RapidAPI-Host': 'instagram-scraper-20231.p.rapidapi.com'
        }
      };

      try {
        const response = await axios.request(options);
        console.log(response.data);
        (response.data.data.edges).forEach(element => {
            console.log(element.node.thumbnail_resources[2]);
            (this.imageList).push(element)
          });
      } catch (error) {
        console.error(error);
      }
    },
    async submit() {
      const axios = require('axios');

      const options = {
        method: 'GET',
        url: 'https://instagram-scraper-20231.p.rapidapi.com/searchuser/' + this.search,
        headers: {
          'X-RapidAPI-Key': 'dc2d3b6a28msh63bc89ad0929e18p1fe1f0jsn5847ba2f24d2',
          'X-RapidAPI-Host': 'instagram-scraper-20231.p.rapidapi.com'
        }
      };

      try {
        const response = await axios.request(options);
        console.log(response.data);
          (response.data.data).forEach(element => {
                if (!element.user.is_private){
                  (this.listBox).push(element)
                }
              });
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style>
.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); /* Sloupce s minimální šířkou 320px a flexibilní šířkou */
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: #f4f5;
  padding: 0 20px;
}

::selection {
  color: #fff;
  background: #664AFF;
}

.container {
  max-width: 450px;
  margin: 150px auto;
}

.container .searchInput {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
}

.searchInput input {
  height: 55px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 18px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.searchInput.active input {
  border-radius: 5px 5px 0 0;
}

.searchInput .resultBox {
  padding: 0;
  max-height: 280px;
  overflow-y: auto;
}

.searchInput.active .resultBox {
  padding: 10px 8px;
  opacity: 1;
  pointer-events: auto;
}

.resultBox li {
  list-style: none;
  padding: 8px 12px;
  width: 100%;
  cursor: default;
  border-radius: 3px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.resultBox li img {
  border-radius: 50px;
  width: 42px;
  height: 42px;
  margin-right:10px;
  object-fit: cover;
}

.searchInput.active .resultBox li {
  display: block;
}

.resultBox li:hover {
  background: #efefef;
}

.searchInput .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 20px;
  color: #644bff;
  cursor: pointer;
}
</style>
